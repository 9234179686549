$(function(){

var swiper = new Swiper('.swiper-container', {
    speed: 833,
    loop: true,
    autoplay: {
      delay: 4167,
    },
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : true
    },
    breakpoints: {
      760: {
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  });

});
